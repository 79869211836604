export const UPDATE_SHARED_PRODUCT_TYPES = 'UPDATE_SHARED_PRODUCT_TYPES';

const INITIAL_STATE = {
    productTypes: [],
    allTenors: [],
    brokerages: []
};

export const updateSharedData = (data) => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_SHARED_PRODUCT_TYPES,
            data
        });
    };
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_SHARED_PRODUCT_TYPES:
            return {
                ...state,
                ...action.data
            };

        default:
            return state;
    }
};

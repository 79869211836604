import { lazy } from 'react';

export const LoginContainer = lazy(() => import('containers/Login/LoginContainer'));

export const PageNotFoundContainer = lazy(() => import('containers/PageNotFound/PageNotFoundContainer'));

export const TestingContainer = lazy(() => import('containers/_Testing/TestingContainer'));

export const ConfirmContainer = lazy(() => import('containers/Confirm/ConfirmContainer'));

export const HomeContainer = lazy(() => import('containers/Home/HomeContainer'));
export const OrderBookContainer = lazy(() => import('containers/OrderBook/OrderBookContainer'));
export const NegotiationContainer = lazy(() => import('containers/Negotiation/NegotiationContainer'));
export const SettingsContainer = lazy(() => import('containers/Settings/SettingsContainer'));
export const RegistrationContainer = lazy(() => import('containers/Registration/RegistrationContainer'));
export const ReportsContainer = lazy(() => import('containers/Reports/ReportsContainer'));
export const BulkContainer = lazy(() => import('containers/BulkOrder/BulkContainer'));
export const DataApiContainer = lazy(() => import('containers/DataApi/DataApiContainer'));
export const NewRegistrationContainer = lazy(() => import('containers/NewRegisteration/NewRegistrationContainer'));
export const NewLoginContainer = lazy(() => import('containers/NewLogin/NewLoginContainer'));
export const SignUpContainer = lazy(() => import('containers/SignUp/SignUpContainer'));
export const WellcomeMoContainer = lazy(() => import('containers/WellcomeMo/WellcomeMoContainer'));

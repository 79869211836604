import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import './BrokerageSelect.scss';

import classNames from 'classnames';
import CheckBox from 'creatella-react-components/lib/CheckBox';

BrokerageSelect.propTypes = {
    brokeragesList: PropTypes.array.isRequired,
    defaultSelectedBrokerages: PropTypes.array,
    error: PropTypes.string,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    qty: PropTypes.string,
    isSplit: PropTypes.bool,
    isBlock: PropTypes.bool
};

function BrokerageSelect({ brokeragesList, defaultSelectedBrokerages, error, onChange, readOnly, qty = null, isSplit = false, isBlock = false }) {
    const [selectedBrokerages, setSelectedBrokerages] = useState(defaultSelectedBrokerages || []);

    useEffect(() => {
        if (JSON.stringify(defaultSelectedBrokerages) !== JSON.stringify(selectedBrokerages)) {
            setSelectedBrokerages(defaultSelectedBrokerages);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultSelectedBrokerages]);

    useEffect(() => {
        if (onChange) {
            onChange(selectedBrokerages);
        }
    }, [onChange, selectedBrokerages]);

    const onClickBrokerage = (brokerageId) => () => {
        if (readOnly) {
            return;
        }

        if (isBlock) {
            // only select one brokerage
            setSelectedBrokerages(prevValue => {
                if (prevValue.includes(brokerageId)) {
                    return prevValue.filter(i => i !== brokerageId);
                } else {
                    return [brokerageId];
                }
            });
        } else {
            setSelectedBrokerages(prevValue => {
                if (prevValue.includes(brokerageId)) {
                    return prevValue.filter(i => i !== brokerageId);
                } else {
                    return [...prevValue, brokerageId];
                }
            });
        }
    };

    const onClickSelectAll = () => {
        if (readOnly) {
            return;
        }

        if (selectedBrokerages.length === brokeragesList.length) {
            setSelectedBrokerages([]);
        } else {
            setSelectedBrokerages(brokeragesList.map(b => b.id));
        }
    };

    let brokeragesToRender = brokeragesList;

    if (readOnly) {
        brokeragesToRender = brokeragesList.filter(b => selectedBrokerages.includes(b.id));
    }

    return (
        <div className={classNames('brokerage-select', { 'brokerage-select--readonly': readOnly })} >
            <div className={classNames('brokerage-select__wrapper', { 'brokerage-select__wrapper--error': error })} >
                {
                    brokeragesList.length === 0 && <div className='brokerage-select__no-brokerages-text'>
                        No Brokerages Available
                    </div>
                }
                <div className={classNames('brokerage-select__btn-wrapper', { 'brokerage-select__show-qty': qty })} >
                    {
                        brokeragesToRender.map(b => <div className={classNames({ 'brokerage-select__broker-box': qty })} key={b.id}>
                            <div onClick={onClickBrokerage(b.id)} className={classNames('brokerage-select__btn', { 'brokerage-select__btn--selected': selectedBrokerages.includes(b.id), 'brokerage-select__btn--active': !readOnly })}>
                                <div>{b.name}</div>
                            </div>
                            {qty && !isBlock ? <div className='brokerage-select__qty'>{qty}</div> : null}
                        </div>)}
                    {!readOnly && !isBlock ? <div className='brokerage-select__select-all'>
                        <CheckBox
                            className='select-all-brokerage'
                            label='Select All'
                            onChange={onClickSelectAll}
                            isChecked={selectedBrokerages.length === brokeragesToRender.length && selectedBrokerages.length > 0} />
                    </div> : null}
                </div>

            </div>
            {error ? <div className='brokerage-select__error-text'>
                {error}
            </div> : null}
        </div >
    );
}

export default BrokerageSelect;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './Modal.scss';

Modal.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]).isRequired,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.any
    ]).isRequired,
    className: PropTypes.string,
    onClose: PropTypes.func,
    isVisible: PropTypes.bool,
    compact: PropTypes.bool
};

function Modal({ children, title, isVisible, onClose, className, compact }) {
    const shouldVisible = typeof isVisible === 'undefined' ? false : isVisible;

    return (
        !shouldVisible ? false
            : <div className={`modal ${className}`}>
                <div className='modal__backdrop'/>
                <div className='modal__container'>
                    <div className={classNames('modal__wrapper', { 'modal__wrapper--compact': compact })}>
                        <div className={classNames('modal__header', { 'modal__header--compact': compact })}>
                            <div className='modal__title'>
                                {typeof title === 'function' ? title() : title}
                            </div>
                            <button className='modal__close-button' onClick={onClose} />
                        </div>
                        <div className='modal__body'>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default Modal;

import { TRADING_ELOQUENT as ELOQUENT } from 'config/constants';

/**
 * Get logged in user's profile data
 * @param {Object} data
 * @returns {Promise}
 */
export function getProductTypes(data, { token }) {
    return ELOQUENT.ProductTypes.index(data, token);
}

/**
 * Get all tenors
 * @param {Object} data
 * @returns {Promise}
 */
export function getAllTenors(data, { token }) {
    return ELOQUENT.ProductTypes.tenors(data, token);
}

/**
 * Get products without pagination
 * @param {Object} data
 * @returns {Promise}
 */
export function getAllProductTypes(data, { token }) {
    return ELOQUENT.ProductTypes.all(data, token);
}

/**
 * Create product type
 * @param {Object} data
 * @returns {Promise}
 */
export function createProductTypes(data, { token }) {
    return ELOQUENT.ProductTypes.create(data, token);
}

/**
 * Update product type
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateProductTypes(id, data, { token }) {
    return ELOQUENT.ProductTypes.update(id, data, token);
}

/**
 * Update status of product type
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateStatusProductTypes(id, data, { token }) {
    return ELOQUENT.ProductTypes.updateStatus(id, data, token);
}

export const UPDATE_BACK_DROP = 'UPDATE_BACK_DROP';

const INITIAL_STATE = {
    backdropActive: false
};

export const updateBackdrop = (backdropState) => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_BACK_DROP,
            backdropActive: backdropState
        });
    };
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_BACK_DROP:
            return {
                ...state,
                backdropActive: action.backdropActive
            };

        default:
            return state;
    }
};

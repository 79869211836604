/* eslint-disable camelcase */
import { TRADING_ELOQUENT as ELOQUENT } from 'config/constants';

/**
 * Create negotiations.
 * @param {Object} data
 * @returns {Promise}
 */
export function createNegotiation(data) {
    return ELOQUENT.Negotiations.create(data);
}

/**
 * Read all negotiations
 * @param {Object} data
 * @returns {Promise}
 */
export function getNegotiations(data, { token }) {
    return ELOQUENT.Negotiations.index(data, token);
}

/**
 * update a negotiation.
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateNegotiation(id, data) {
    return ELOQUENT.Negotiations.update(id, data);
}

/**
 * accept a negotiation.
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function acceptNegotiation(id, data, { token }) {
    return ELOQUENT.Negotiations.accept(id, data, token);
}

/**
 * cancel a negotiation.
 * @returns {Promise}
 * @param {Number} id
 */
export function cancelNegotiation(id) {
    return ELOQUENT.Negotiations.cancel(id);
}

/**
 * mark a negotiation as done.
 * @returns {Promise}
 * @param {Number} id
 */
export function acknowledgeDone(id) {
    return ELOQUENT.Negotiations.acknowledgeDone(id);
}

/**
 * Get order logs
 * @param {Any} id
 * @returns {Promise}
 */
export function getLogs(id) {
    return ELOQUENT.Negotiations.logs(id);
}

/**
 * Nudge on specific negotiation
 * @param {Any} id
 * @param {Object} data
 * @returns {Promise}
 */
export function nudge(id, data) {
    return ELOQUENT.Negotiations.nudge(id, data);
}

/**
 * Acknowledge nudge
 * @param {Any} id
 * @param {Object} data
 * @returns {Promise}
 */
export function acknowledgeNudge(id, data) {
    return ELOQUENT.Negotiations.acknowledgeNudge(id, data);
}

/**
 * Get negotiation messages
 * @param {Any} id
 * @returns {Promise}
 */
export function getNegoMessages(id) {
    return ELOQUENT.NegotiationMessage.index(id);
}

/**
 * Send a negotiation message
 * @param {Any} id
 * @param {Object} data
 * @returns {Promise}
 */
export function sendNegoMessage(id, data) {
    return ELOQUENT.NegotiationMessage.create(id, data);
}

/**
 * read all nego messages
 * @param {Any} id
 * @returns {Promise}
 */
export function readAllNegoMessages(id) {
    return ELOQUENT.NegotiationMessage.readAll(id);
}

/**
 * read all nego messages
 * @param {Any} id
 * @param {Object} data
 * @returns {Promise}
 */
export function readManyNegoMessages(id, data) {
    return ELOQUENT.NegotiationMessage.readMany(id, data);
}

/**
 * cancel all negotiations
 * @param {Object} data
 * @returns {Promise}
 */
export function cancelAllNegotiations(data, { token }) {
    return ELOQUENT.BulkNegotiations.cancel(data, token);
}

/* eslint-disable react/jsx-max-props-per-line */

import PropTypes from 'prop-types';
import React, { Component, Suspense } from 'react';
import AppLoading from '../Loading/AppLoading';
import { Switch, Route } from 'react-router-dom';
import { ROUTES } from 'config/constants';
import { AuthedRoute, UnauthedRoute } from './components/RouteWrappers';
import {
    LoginContainer,
    PageNotFoundContainer,
    ConfirmContainer,
    HomeContainer,
    OrderBookContainer,
    NegotiationContainer,
    SettingsContainer,
    RegistrationContainer,
    ReportsContainer,
    BulkContainer,
    DataApiContainer,
    NewRegistrationContainer,
    NewLoginContainer,
    SignUpContainer,
    WellcomeMoContainer
} from './config/routes';

// TODO_REPLACE(
//  Route: accessible for anyone
//  UnauthedRoute: accessible for unauthed clients only
//  AuthedRoute: accessible for authed clients only
// )

export default class AppRouter extends Component {
    static propTypes = {
        isAuthed: PropTypes.bool.isRequired
    }

    shouldComponentUpdate(nextProps) {
        const { isAuthed } = this.props;

        if (isAuthed !== nextProps.isAuthed) {
            return true;
        }

        return false;
    }

    render() {
        const { isAuthed } = this.props;

        return (
            <Suspense fallback={<AppLoading showLogo={true} />}>
                <Switch>
                    {/* <UnauthedRoute path={ROUTES.NEW_LOGIN} exact={true} component={NewLoginContainer} isAuthed={isAuthed} /> */}
                    <UnauthedRoute path={ROUTES.SIGN_UP} exact={true} component={SignUpContainer} isAuthed={isAuthed} />
                    <Route path={ROUTES.NEW_LOGIN} exact={true} component={LoginContainer} isAuthed={isAuthed} />
                    <UnauthedRoute path={ROUTES.LOGIN} exact={true} component={NewLoginContainer} isAuthed={isAuthed} />
                    <UnauthedRoute path={ROUTES.RECOVER_PASSWORD} exact={true} component={LoginContainer} isAuthed={isAuthed} />
                    <UnauthedRoute path={ROUTES.RESET_PASSWORD} exact={true} component={LoginContainer} isAuthed={isAuthed} />
                    <UnauthedRoute path={ROUTES.SET_PASSWORD} exact={true} component={LoginContainer} isAuthed={isAuthed} />
                    <UnauthedRoute path={ROUTES.CONFIRM} exact={true} component={ConfirmContainer} isAuthed={isAuthed} />
                    <AuthedRoute path={ROUTES.HOME} exact={true} component={HomeContainer} isAuthed={isAuthed} />
                    <AuthedRoute path={ROUTES.ORDER_BOOK} exact={true} component={OrderBookContainer} isAuthed={isAuthed} />
                    <AuthedRoute path={ROUTES.NEGOTIATION} exact={true} component={NegotiationContainer} isAuthed={isAuthed} />
                    <AuthedRoute path={ROUTES.MANAGEMENT} exact={false} component={SettingsContainer} isAuthed={isAuthed} />
                    {/* <Route path={ROUTES.REGISTRATION} exact={true} component={RegistrationContainer} /> */}
                    <Route path={ROUTES.NEW_REGISTRATION} exact={true} component={NewRegistrationContainer} />
                    <AuthedRoute path={ROUTES.SETTINGS} exact={false} component={SettingsContainer} isAuthed={isAuthed} />
                    <AuthedRoute path={ROUTES.REPORTS} exact={true} component={ReportsContainer} isAuthed={isAuthed} />
                    <AuthedRoute path={ROUTES.BULK} exact={true} component={BulkContainer} isAuthed={isAuthed} />
                    <AuthedRoute path={ROUTES.INDICATIONS} exact={true} component={BulkContainer} isAuthed={isAuthed} />
                    <AuthedRoute path={ROUTES.DATA_API} exact={true} component={DataApiContainer} isAuthed={isAuthed} />
                    <AuthedRoute path={ROUTES.WELCOME_MO} exact={true} component={WellcomeMoContainer} isAuthed={isAuthed} />

                    <Route component={PageNotFoundContainer} />
                </Switch>
            </Suspense>
        );
    }
}

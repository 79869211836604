/* eslint-disable camelcase */
import { TRADING_ELOQUENT as ELOQUENT } from 'config/constants';

/**
 * Get order by id
 * @param {Any} id
 * @returns {Promise}
 */
export function getOrderById(id) {
    return ELOQUENT.Orders.show(id);
}

/**
 * Update order
 * @param {Any} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateOrder(id, data) {
    return ELOQUENT.Orders.update(id, data);
}

/**
 * Cancel order
 * @param {Any} id
 * @returns {Promise}
 */
export function cancelOrder(id) {
    return ELOQUENT.Orders.cancel(id);
}

/**
 * Get a product orders
 * @param {Any} product_id
 * @param {Any} type
 * @returns {Promise}
 */
export function getProductOrders(product_id, type = null, { token }) {
    return ELOQUENT.Orders.index({ product_id, type, per_page: 100 }, token);
}

/**
 * Get a product orders
 * @param {Object} data
 * @returns {Promise}
 */
export function createOrder(data) {
    return ELOQUENT.Orders.create(data);
}

/**
 * Get order logs
 * @param {Any} id
 * @returns {Promise}
 */
export function getLogs(id) {
    return ELOQUENT.Orders.logs(id);
}

/**
 * Cancel active orders
 * @param {Any} data
 * @returns {Promise}
 */
export function cancelOrders(data, { token }) {
    return ELOQUENT.BulkOrders.cancel(data, token);
}

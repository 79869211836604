import { CANCEL_TYPE } from 'config/constants';

export const SHOW_CANCEL_MODAL = 'SHOW_CANCEL_MODAL';
export const CLOSE_CANCEL_MODAL = 'CLOSE_CANCEL_MODAL';
const INITIAL_STATE = {
    shouldCancelModalShown: false,
    cancelType: CANCEL_TYPE.ALL_ORDERS,
    companyOrders: false
};

export const showCancelModal = (cancelType = CANCEL_TYPE.ALL_ORDERS, companyOrders) => {
    return async (dispatch) => {
        dispatch({
            type: SHOW_CANCEL_MODAL,
            cancelType,
            companyOrders
        });
    };
};

export const closeCancelModal = () => {
    return async (dispatch) => {
        dispatch({
            type: CLOSE_CANCEL_MODAL
        });
    };
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_CANCEL_MODAL:
            return {
                ...state,
                shouldCancelModalShown: true,
                cancelType: action.cancelType,
                companyOrders: action.companyOrders
            };

        case CLOSE_CANCEL_MODAL:
            return {
                ...state,
                shouldCancelModalShown: false,
                cancelType: CANCEL_TYPE.ALL_ORDERS,
                companyOrders: false
            };

        default:
            return state;
    }
};
